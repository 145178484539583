<template>
  <div class="card card-custom">
    <modal name="add-category"
           adaptive: true
           :height="'auto'"
           :width="700"
           @closed="clearCategory"
    >
      <div class="add-category">
        <form @submit.prevent="save">
          <div class="form-group row">
            <label class="col-sm-2 col-form-label">{{ nameModalUpdate }}</label>
            <div class="col-sm-8">
              <input
                  type="text"
                  class="form-control"
                  v-model="name"
              >
            </div>
            <div class="col-sm-2">
              <button class="btn btn-outline-success" type="submit">Сохранить</button>
            </div>
          </div>
        </form>
      </div>
    </modal>
    <div class="card-header flex-wrap border-0 pt-6 pb-0">
        <div class="card-title">
            <h3 class="card-label">Разряды</h3>
        </div>        
        <div class="card-toolbar">
            <a href="#"  @click="addCategory({nameCategory: 'category', url: 'category', nameModalUpdate: options.headings['category']})">Добавить</a>
        </div>    
    </div>
    <div class="card-body">
      <CategoryTable      
        :columns="['category', 'actions']"
        :options="options"
        :data="getDataCategory['category']"
        :slot_="'category'"
        @remove="delItem"
        @update="updateItem"
      /> 
    </div>
  </div>
</template>

<script>
  import {mapGetters, mapActions, mapMutations} from 'vuex'
  import CategoryTable from "@/components/table/CategoryTable";
  const DelCategory = () => import("../../components/table/DelToponym/DelCategory")
  import EditBtn from "../../components/table/EditBtn"
  import DeleteBtn from "../../components/table/DeleteBtn"

  export default {
    name: 'Category',
    computed: {
      ...mapGetters([
        'getDataCategory',
        'getActionsCategory'
      ])
    },
    data() {
      return {
        item: {},
        nameCategory: '',
        nameModalUpdate: '',
        name: 'category',
        sav: false,
        isData: false,
        options: {
          headings: {
            region: 'Регион',
            category: 'Разряд',
            object_type: 'Тип объекта',
            actions: 'Действия'
          },
          columnsClasses: {
            'actions': 'w-100px'
          },
          filterable: false,
          sortIcon: {
            base: 'la',
            up: 'la-long-arrow-up text-success pt-1',
            down: 'la-long-arrow-down text-success pt-1',
            is: 'la-arrows-v text-muted pt-1'
          }
        },
      }
    },
    methods: {
      ...mapActions(['SET_CATEGORY',]),
      ...mapMutations(['SET_ACTIONS_CATEGORY']),
      addCategory({nameCategory, url, nameModalUpdate}) {
        this.nameModalUpdate = nameModalUpdate
        this.item.url = url
        this.nameCategory = nameCategory
        this.$modal.show('add-category')
        this.sav = true
      },
      save() {
        if (this.sav) {
          this.SET_CATEGORY({
            method: 'post',
            url: this.item.url,
            data: {name: this.name},
            commitName: 'SET_NEW_CATEGORY',
            notify: this.$notify,
            modal: this.$modal,
            modalName: 'add-category'
          })
        } else {
          this.SET_CATEGORY({
            method: 'post',
            url: this.getActionsCategory.url,
            data: {_method: 'put', name: this.name},
            commitName: 'updateCategory',
            id: this.getActionsCategory.id,
            notify: this.$notify,
            modal: this.$modal,
            modalName: 'add-category'
          })
        }
      },
        delItem(item)
        {
          this.SET_ACTIONS_CATEGORY({
            method: 'delete',
            url: 'category',
            commitName: 'delCategory',
            text: 'Вы точно хотите удалить эту запись',
            id: item.row.id
          })
          this.$modal.show(DelCategory,
          {}, {
            width:400,
            height: 'auto',
            scrollable: true
          })
        }
      ,
        updateItem(item) {
          this.sav = false
          this.nameModalUpdate = this.options.headings[item.column]
          this.item.name = item.row.name
          this.name = item.row.name

          this.SET_ACTIONS_CATEGORY({
            method: 'post',
            url: 'category/' + item.row.id,
            data: {_method: 'put', name: this.name},
            commitName: 'updateCategory',
            id: item.row.id
          })
          this.$modal.show('add-category')
        }
      ,
        clearCategory()
        {
          this.name = ''
        }
      },
      mounted() {
        this.$store.commit('updateIsLoading', true)
        axios.get('/category')
            .then(response => {
              this.$store.commit('setCategories', response.data)
              this.$store.commit('updateIsLoading', false)
            })
      },
      components: {
        CategoryTable,
        DelCategory,
        EditBtn,
        DeleteBtn
      }
    }
</script>

<style>
  .card-body {
    background: #fafafa;
  }
  .add-button .VueTables__sortable {
    width: 33%;
  }

  .add-category {
    padding: 30px 30px 10px 30px;
  }

  .form-group input {
    border: 1px solid #cdcccc;
    border-radius: 5px;
  }

</style>
